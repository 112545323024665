form {
  padding: 10px 20px;
  /* background: #f4f7f8; */
  border-radius: 8px;
}
  
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea,
select {
  background: rgba(255,255,255,0.1);
  border: none;
  font-size: 16px;
  height: auto;
  /* margin: 5px; */
  outline: 0;
  padding: 5px;
  width: 100%;
  background-color: #ffffff;
  /* color: #8a97a0; */
  box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  margin-bottom: 30px;
}
  
input[type="radio"],
input[type="checkbox"] {
  margin: 0 4px 8px 0;
}

button {
  /* padding: 19px 39px 18px 39px; */
  padding: 10px;
  color: #FFF;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  /* font-size: '200%'; */
  /* font-style: normal; */
  /* background-color: #4b70c9; */
  /* border: 1px solid #aec1ed; */
  /* border-width: 1px 1px 3px 30px; */
  /* box-shadow: 0 -1px 0 rgba(255,255,255,0.1) inset; */
  margin-bottom: 2px;
}

.edit {
  background-color: hsl(250, 90%, 70%);
  border: 1px solid hsl(250, 90%, 95%);
}
.add {
  background-color: hsl(100, 90%, 30%);
  border: 1px solid hsl(100, 90%, 95%);
}
.save {
  background-color: hsl(0, 90%, 70%);
  border: 1px solid hsl(0, 90%, 95%);
}


fieldset {
  margin-bottom: 30px;
  border: none;
}

legend {
  font-size: 1.4em;
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 8px;
}

label.light {
  font-weight: 300;
  display: inline;
}

.number {
  background-color: #4b70c9;
  color: #fff;
  height: 30px;
  width: 30px;
  display: inline-block;
  font-size: 0.8em;
  margin-right: 4px;
  line-height: 30px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255,255,255,0.2);
  border-radius: 100%;
}




.overlay {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 105px;
  transition: 1s;
  
}

.overlay .active {
  top: calc(-50% + 105px);
  width: 160%;
  height: 160%;
  left: -30%;
}
